'use strict'
import fetch from '@elements/fetch';
import {getLoadingContainer} from './loading'
import asyncAppend from '@elements/async-append';
import { translate } from '@elements/translations'
import shuffle from 'shuffle-array';
import { Pagination } from 'paginatedjs/build/paginate-js.min'
import pagination from 'pagination'

const _token = 'a3a0797b0861e9400c942504c84f341aec2f2994';
const _language = _config.lang || 'de';
const _ean = _config.ean;
//const _country = _config.locale.split('_')[1] || 'DE';
const _apiUrl = new URL('/REST/2.0', 'https://api-retailer.global.commerce-connector.com');

export async function initInScope($scope) {

    $scope.find('.js-commerceConnector__btn').on('click', function() {

        $(this).closest('.modal').find('.js-commerceConnector__btn').removeClass('country-is-active');
        $(this).addClass('country-is-active');

        const _country = $(this).attr('data-country');
        let _eanNumber = $(this).attr('data-ean') || _ean;
        const
            $modal = $scope.find('.modal--commerceConnectorOnline');

        let url = new URL(_apiUrl + '/OnlineShop');
        url.searchParams.set('token', _token);
        url.searchParams.set('O_ResultFields', "Id,Name,OfferInfo,Currency,AddressInfo,Description,ContactInfo,Priority,Logos,CustomerRestriction,Currency,PaymentOptions,ShippingDefinitions,ProductGroups,Images,Events,CustomFields");
        url.searchParams.set('F_Ean', _eanNumber);
        url.searchParams.set('Country', _country);
        url.searchParams.set('F_SubId', 'website');
        url.searchParams.set('O_ResultAmount[Limit]', '54');

        const templateString = document.getElementById('commerceConnectorOnlineStoresTemplate').innerHTML;
        let pageItems;
        const itemsPerPage = 18;


        function initStores(stores) {
            /*if(stores.length >= 1) {
                $scope.find('.js-commerceConnector__buttons').show();
            }*/
            pageItems = new Pagination(stores, itemsPerPage)
            return renderStores()
        }

        function renderStores(page = 1){
            let resultHtml = '';

            pageItems.goToPage(page)
            const stores = pageItems.getPaginated(true)

            if(stores.length >= 1) {

                stores.forEach(function (store) {
                    resultHtml += printStore(store)
                })

                resultHtml += renderPagination();

                return resultHtml;
            } else {
                $modal.find('.js-commerceConnector__online-result').empty().append('<div class="commerceConnector__no-results">'+translate('commerce.connector.no-stores')+'</div>');
            }
        }

        function renderPagination() {
            let paginator = new pagination.TemplatePaginator({
                prelink:'#',
                current: pageItems.pageNumber,
                rowsPerPage: itemsPerPage,
                totalResult: pageItems.count(),
                slashSeparator: false,
                template: function(result) {
                    var i, len, prelink;
                    var html = '<div class="col-12"><ul class="pagination d-flex justify-content-center">';
                    if(result.pageCount < 2) {
                        html += '</ul></div>';
                        return html;
                    }
                    prelink = this.preparePreLink(result.prelink);
                    if(result.previous) {
                        html += '<li class="page-item"><a class="page-link js-commerceConnector__pagination-goTo" data-page-id="' + result.previous + '" href="' + prelink + result.previous + '">' + translate('commerce.connector.page.prev') + '</a></li>';
                    }
                    if(result.range.length) {
                        for( i = 0, len = result.range.length; i < len; i++) {
                            if(result.range[i] === result.current) {
                                html += '<li class="active page-item"><span class="page-link">' + result.range[i] + '</span></li>';
                            } else {
                                html += '<li class="page-item"><a class="page-link js-commerceConnector__pagination-goTo" data-page-id="' + result.range[i] + '" href="' + prelink + result.range[i] + '">' + result.range[i] + '</a></li>';
                            }
                        }
                    }
                    if(result.next) {
                        html += '<li class="page-item"><a class="page-link js-commerceConnector__pagination-goTo" data-page-id="' + result.next + '" href="' + prelink + result.next + '" class="paginator-next">' + translate('commerce.connector.page.next')  + '</a></li>';
                    }
                    html += '</ul></div>';
                    return html;
                }
            });
            return paginator.render();
        }

        function bindPaginationFunctions() {
            const $items      = $scope.find('.js-commerceConnector__pagination-goTo');

            $items.on('click', (e) => {
                e.preventDefault();
                const page = $(e.delegateTarget).data('page-id');
                bindPaginationFunctions($modal.find('.js-commerceConnector__online-result').empty().append(renderStores(page)));
            })

        }

        function printStore(store){
            return templateString
                .replace(/{name}/g, store.Name)
                //.replace(/{description}/g, store.Description)
                .replace(/{link}/g, store.OfferInfo.Offers[0].Deeplink)
                .replace(/{img}/g, store.Logos[0].LogoSmall)
            //.replace(/{delivery}/g, parseInt(store.OfferInfo.Offers[0].AvailabilityInfo[0].DeliveryTime) > 0 ? store.OfferInfo.Offers[0].AvailabilityInfo[0].DeliveryTime : translate('commerce.connector.no.delivery.time.available'))
        }

        asyncAppend({
            $target: $modal.find('.js-commerceConnector__online-result'),
            $loading: $(getLoadingContainer()),
            $notification: $modal.find('.js-commerceConnector_online-notifications')
        }, fetch(url).then((res) => {
            return res.clone().json()
        }).then((res) => {
            try {
                if (res.getResult[0].ResultGroups[0].OnlineShops) {
                    //random order every new page load: (client request)
                    //const stores = shuffle(res.getResult[0].ResultGroups[0].OnlineShops);
                    const stores = res.getResult[0].ResultGroups[0].OnlineShops;
                    res.html = initStores(stores);
                }
            }catch(err) {
                res.html = translate('commerce.connector.no-stores');
                console.warn('error when accessing stores');
            }
            return res;
        })).then((params) => {
            bindPaginationFunctions();
        })

    });


}