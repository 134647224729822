import {unsetAffix, setAffix, getNavbarHeight} from './affixNavbar';

export function init() {
    if (matchMedia('(max-width: 767px)').matches) {
        $('.js-nav__link').on('click', function (evt) {
            console.log($(this), "clicked");
            let $currentLink = $(this);
            let $nav = $currentLink.closest('.js-nav');
            let $navItems = $nav.find('.js-nav__item');
            let $navItem = $currentLink.closest('.js-nav__item');

            evt.preventDefault();

            if($navItem.hasClass('is-open')) {
                $nav.removeClass('is-open');
                $navItem.removeClass('is-open');
            } else {
                $nav.addClass('is-open');
                $navItem.addClass('is-open');
            }
        });

        $('.js-nav__close').on('click', function () {
            let $close = $(this);
            let $nav = $close.closest('.js-nav');
            let $navItems = $nav.find('.js-nav__item');
            $navItems.removeClass('is-open');
        });

        $('.js-nav__toggle').on('click', function () {
            let $close = $(this);
            let $nav = $close.closest('.js-nav');
            let $navItems = $nav.find('.js-nav__item');

            $nav.toggleClass('is-open');
            $navItems.removeClass('is-open');

            if ($nav.hasClass('is-open')) {
                $('html').addClass('is-scrolling-disabled');
            } else {
                $('html').removeClass('is-scrolling-disabled');
            }
        });


        $('.js-nav__go-back').on('click', function() {
            let $goBack = $(this);
            let $navItem = $goBack.closest('.js-nav__item');
            $navItem.removeClass('is-open');
        });
    }
}