"use strict";

let id = 0;
let lastId;
let $scrollNav = $('#scrollspy-nav');
let spyItems;
let offset;
let navItems;
let $firstSpyItem;


export function init() {
    let $navContent = $(".js-scrollspy-nav__content");

    $navContent.each(function () {
        let $this = $(this);
        let title = $this.data('nav-name');
        let titleForAnchor = title.replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '').toLowerCase();

        if(title && titleForAnchor) {
            if($this.attr('id')){
                titleForAnchor = $this.attr('id').replace(/[^a-z0-9\s]/gi, '').replace(/[_\s]/g, '').toLowerCase();
                $this.attr('id', titleForAnchor);
                id++;
                addNavItem(title, titleForAnchor);
            }else{
                $this.attr('id', titleForAnchor);
                id++;
                addNavItem(title,titleForAnchor);
            }
        }

    });

    offset = $scrollNav.data('scrollspy-offset');
    navItems = $scrollNav.find(".nav-link");

    spyItems = navItems.map(function(){
        let href = $(this).attr("href");
        if(href && href !== '' && href !== '#') {
            let $item = $(href);
            if ($item.length) {
                return $item;
            }
        }
    });

    if(spyItems.length > 0) {
        scrollSpyHandler();

        $firstSpyItem = $(spyItems[0]);

        if($firstSpyItem.offset().top <= $(window).scrollTop() + offset) {
            $scrollNav.find("[href='#"+$firstSpyItem.attr('id')+"']").closest('li').addClass('dot--active');
        }
    }

    let $navItem = $(".js-scrollspy-nav__item a");

    $navItem.each(function () {
        $(this).mouseover(function (evt) {
            let title = $(this).find(".nav-title");
            title.addClass("show-nav-text");
        })
    });

    $navItem.each(function () {
        $(this).mouseout(function (evt) {
            let title = $(this).find(".nav-title");
            title.removeClass("show-nav-text");
        })
    });

}

/*

function addNavItem(text, anchorId) {
    $('#js-scrollspy-nav__list').append(' <li class="d-flex align-items-center nav-item js-scrollspy-nav__item">\n' +
        '            <div class="nav-title__container position-relative d-flex align-items-center">\n' +
        '                <p class="nav-title mb-0 mr-3">' + text + '</p>\n' +
        '            </div>\n' +
        '            <a href="#' + anchorId + '" class="dot nav-link js-scroll-to"></a>\n' +
        '        </li>');
}
*/
function addNavItem(text, anchorId) {
    $('#js-scrollspy-nav__list').append(' <li class="nav-item js-scrollspy-nav__item">\n' +
        '            <a href="#' + anchorId + '" class="dot nav-link js-scroll-to"><div class="nav-title">'+text+'</div></a>\n' +
        '        </li>');
}

function scrollSpyHandler() {
    $(window).scroll(function () {
        let scrollTopPosition = $(this).scrollTop() + offset;

        let currentItem = spyItems.map(function(){
            if ($(this).offset().top < scrollTopPosition)
                return this;
        });

        // Get id of the current element
        currentItem = currentItem[currentItem.length-1];
        let itemId = currentItem && currentItem.length ? currentItem[0].id : "";

        if (lastId !== itemId) {
            lastId = itemId;
            navItems.parent().removeClass("dot--active").end().filter("[href='#"+itemId+"']").parent().addClass("dot--active");
        }
    });
}
