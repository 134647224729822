"use strict";

export function initInScope($scope) {
    let $filter = $scope.find('.js-product-grid-dropdown-filter');
    $filter.each(function() {
        let $dropdown = $(this);
        let $toggle = $dropdown.find('.js-product-grid-dropdown-filter__toggle');

        $dropdown.on('shown.bs.dropdown', function (evt) {
            $('body').on('click', bodyClickHandler);

            // remove body click handler so the click after the product-grid refresh is normal
            $('.js-product-grid').one('product-grid.changed', function () {
                $('body').off('click', bodyClickHandler);
            });
        });

        $dropdown.on('hidden.bs.dropdown', function () {
            $('body').off('click', bodyClickHandler);
        })
    });
}

function bodyClickHandler (evt) {
    if ($(evt.target).parents('.js-product-grid-dropdown-filter').length < 1) {
        $('.js-product-grid-dropdown-filter__toggle').dropdown('hide');
        $('body').off('click', bodyClickHandler);
    } else {
        evt.stopImmediatePropagation();
    }
}
