import 'slick-carousel';

export function initInScope ($scope) {
    let $sliders = $scope.find('.js-media-slider');

    $sliders.each(function () {
        let $sliderWrapper = $(this).closest('.js-media-slider__wrapper');

        $(this).slick({
            slidesToShow: 4.7,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            fade: false,
            draggable: true,
            infinite:false,
            prevArrow: $sliderWrapper.find('.slider__arrow.arrow-prev'),
            nextArrow: $sliderWrapper.find('.slider__arrow.arrow-next'),
            responsive: [{
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.4,
                }
            }]
        });
    });
}
