"use strict";

export function init () {
    if (matchMedia('(min-width: 768px)').matches) {
        let $items = $('.js-menu-aim > .js-nav__item');
        let $submenu = $('.js-menu-aim__sub-menu');
        let timeout = 300;
        let timer;

        $items.on('mouseenter', function() {
            let $element = $(this);
            clearTimeout(timer);

            timer = setTimeout(function() {
                $items.removeClass('is-open');
                $submenu.removeClass('show-menu');

                $element.addClass('is-open');
                $element.find('.js-menu-aim__sub-menu').addClass('show-menu');
            }, timeout);
        });

        $items.on('mouseleave', function() {
            clearTimeout(timer);

            timer = setTimeout(function() {
                $items.removeClass('is-open');
                $submenu.removeClass('show-menu');
            }, timeout);
        });
    }
}