"use strict";

export function init () {
    if(!_config.openModalId) {
        console.warn('_config.openModal is not defined');
        return;
    }

    let $modal = $(_config.openModalId);

    if($modal && $modal.length > 0) {
        $modal.modal('show');
    } else {
        console.warn('openModal: Modal ' + _config.openModalId + ' not found!');
    }
}