"use strict";

let sliderId = 0;


export function initInScope($scope) {
    let $thumbSlider = $scope.find('.js-product-thumb-slider');

    $thumbSlider.each(function () {
        let $container = $(this);
        let $mainSlider = $container.find('.js-product-thumb-slider__main');
        let $thumbSlider = $container.find('.js-product-thumb-slider__thumbs');
        let id = sliderId++;

        $mainSlider.attr('id', 'main-slider__main-' + id);
        $thumbSlider.attr('id', 'thumb-slider__thumb-' + id);

        setTimeout(function() {

        $mainSlider.slick({
            asNavFor: '#thumb-slider__thumb-' + id,
            dots: true,
            touchMove: false,
            infinite: false,
            arrows:false,
            swipe: false,
            cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
            speed:500,
            fade:false,
            responsive: [{
                breakpoint: 767,
                settings: {
                    fade:true,
                    cssEase: 'linear'
                }
            }]
        });

        if ($thumbSlider && $thumbSlider.length) {
            $thumbSlider.slick({
                dots: true,
                infinite: false,
                slidesToShow:4,
                slidesToScroll:1,
                swipeToSlide: true,
                adaptiveHeight: true,
                vertical:true,
                variableWidth:false,
                verticalSwiping:true,
                prevArrow: '<button type="button" class="slider__arrow slider__arrow--small arrow-prev mb-2 w-100" aria-label="Prev" title="Prev"><span class="icon icon-chevron-up" aria-hidden="true"></span></button>',
                nextArrow: '<button type="button" class="slider__arrow slider__arrow--small arrow-next mt-2 w-100" aria-label="Next" title="Next"><span class="icon icon-chevron" aria-hidden="true"></span></button>',
                asNavFor: '#main-slider__main-' + id,
                focusOnSelect: true,
                responsive: [{
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2
                    }
                }]
            });
        }

        }, 400);
    });
}