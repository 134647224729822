"use strict";

export function initInScope($scope) {
    $scope.find('.js-svg-converter').each(function() {
        let $elem = $(this);
        let svgFileString = $elem.html();

        let svgData = $elem.data('json');
        let attributes = [];

        if (svgData) {
            if (!window['_svgTransform']) {
                console.error('_svgTransform is not set');
            } else if (!window._svgTransform[svgData]) {
                console.error(svgData + ' is not set in _svgTransform', _svgTransform);
            } else {
                attributes = _svgTransform[svgData];
            }

            for (let key in attributes) {
                let replaceStr = "#"+key+"#";
                svgFileString = svgFileString.replace(new RegExp(replaceStr, "g"), attributes[key]);
            }
            $elem.html(svgFileString);
        }

        let $items = $elem.find('.region-map__item');
        $items.on('click', function() {
            let $item = $(this);
            window.location.pathname = $item.data('href');
        });
    });
}
