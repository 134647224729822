import * as wishlistPackage from '../libs/@elements/wishlist';

let myWishlist = null;
if (window['_wishlist'] && window['_wishlist'].wishlist) {
    myWishlist = wishlistPackage.createWishlist({
            ...window['_wishlist'].wishlist
        }
    );

}

export function init() {
    if (myWishlist) {
        myWishlist.init();
    }

    // update counter
    $(document).on('renderd.wishlist', (evt ,params) => {
        if(params.activeIds){
            const count = params.activeIds.length;
            if(count <= 0) {
                $('.js-wishlist__items-count').text(count);
                $('.js-wishlist__items-count-wrapper').attr('hidden', 'hidden');
            }else {
                $('.js-wishlist__items-count').text(count);
                $('.js-wishlist__items-count-wrapper').attr('hidden', null);
            }
        }
    });
}

export function initInScope($scope) {
    if (myWishlist) {
        myWishlist.initInScope($scope);
        // removing in wishlist removes the whole item
        let $wishListItem = $scope.find('.js-wishlist__item');
        $wishListItem.each((_, el) => {
            const $item    = $(el),
                $button     = $item.find('.js-wishlist__remove'),
                id          = $item.data('wishlist-id');
            $button.on('click', function (e) {
                e.preventDefault();
                let _request = myWishlist.remove(id, {showUndo: false});
                _request.then(function (res) {
                    if(res.status = 'success'){
                        $item.remove();
                    }
                })
            })
        })
    }
}



