"use strict";

import initModulesInScope from "@elements/init-modules-in-scope";

export function initInScope($scope) {
    let $input = $scope.find('.js-show-template-on-change__input');
    $input.on('change', function () {
        showTemplate($(this));
    });
}

export function init() {
    let $input = $('.js-show-template-on-change__input');

    $input.each(function() {
        showTemplate($(this));
    });
}

function showTemplate($input) {
    let $wrapper = $input.closest('.js-show-template-on-change'),
        $template = $($wrapper.find('.js-show-template-on-change__template').html()),
        $templateTarget = $wrapper.find('.js-show-template-on-change__target');

    if (!$wrapper.length > 0 ) {
        console.warn('showTemplateOnChange: Can\'t find js-show-template-on-change');
    }
    if (!$wrapper.find('.js-show-template-on-change__template').length > 0 ) {
        console.warn('showTemplateOnChange: Can\'t find js-show-template-on-change__template');
    }
    if (!$templateTarget.length > 0 ) {
        console.warn('showTemplateOnChange: Can\'t find js-show-template-on-change__target');
    }

    $templateTarget.html('');

    let $newTemplate;

    for (let counter = 1; counter <= $input.val(); counter++) {
        $newTemplate = $($template.clone());
        let $newTemplateItem = $newTemplate.find('.js-show-template-on-change__item');

        let itemPrefix = $newTemplateItem.data('form-group-item-prefix') || '',
            $itemFormControl = $newTemplateItem.find('.js-show-template-on-change__form-control'),
            $itemCounter = $newTemplate.find('.js-show-template-on-change__counter');

        $itemFormControl.each(function(_, item) {
            let $this = $(item);
            let $itemLabel = $this.find('.js-show-template-on-change__label');

            $this.attr('id', itemPrefix + counter);
            $this.attr('name', $this.attr('name') + counter);
            $itemLabel.attr('for', itemPrefix + counter);
        });

        $itemCounter.html(counter);

        $templateTarget.append($newTemplate);
        initModulesInScope($newTemplate);
    }

}