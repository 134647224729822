import {getInputState, removeInput} from "@elements/product-grid";

const defaultSelectors = {
    base: '.js-product-grid-quick-filter',
    container: '.js-product-grid',
    template: '.js-product-grid-quick-filter__template',
    text: '.js-product-grid-quick-filter__text'
};

export function createQuickFilter(options = {}, selectors = {}) {
    options = {
        ...defaultSelectors,
        ...options
    };

    selectors = {
        ...defaultSelectors,
        ...selectors
    };

    function renderQuickFilters($quickFilter, $productGrid) {
        $quickFilter.empty();
        getInputState($productGrid).forEach(function (inputState) {
            if (!inputState.hidden && inputState.value !== '$$EMPTY$$' && inputState.name !== 'categoryId') {
                let $newButton = createQuickFilter(inputState);

                $newButton.on('click', function () {
                    if (inputState.name.indexOf('[]')) {
                        removeInput($productGrid, inputState.name, inputState.value);
                    } else {
                        removeInput($productGrid, inputState.name);
                    }
                });

                $quickFilter.append($newButton);
            }
        });
    }

    function createQuickFilter(inputState) {
        let $newQuickFilterButton = $($(selectors.template).html());
        $newQuickFilterButton.find(selectors.text).text(inputState.text);
        return $newQuickFilterButton;
    }

    return {
        initInScope: function ($scope) {
            $scope.find(selectors.base).each(function () {
                let $quickFilter = $(this);
                let $productGrid;

                if ($quickFilter.data('product-grid')) {
                    $productGrid = $($quickFilter.data('product-grid'));
                    if (!$productGrid || !$productGrid.length) {
                        console.error('Can\'t find product grid with selector "' + $quickFilter.data('product-grid')
                            + '" for quickfilter', $quickFilter);
                    }

                    return;
                } else {
                    $productGrid = $quickFilter.closest(selectors.container);
                }

                if (!$productGrid || !$productGrid.length) {
                    console.error('Can\'t find product grid for quickfilter. Add a data-product-grid selector or place the element inside a ' + selectors.container, $quickFilter);
                    return;
                }

                $productGrid.on('product-grid.changed', function (evt) {
                    renderQuickFilters($quickFilter, $productGrid);
                });

                $productGrid.on('product-grid.success', function (evt) {
                    const cutContainer = $productGrid.find('.js-product-grid__cut');
                    if (typeof cutContainer.html() !== 'undefined'){
                        const cutElement = $(cutContainer.html());
                        const cutElementClassName = cutElement.attr('class');
                        const replaceContainer = $('[class="'+cutElementClassName+'"]');
                        replaceContainer.replaceWith(cutContainer.html());
                        cutContainer.remove();
                    }
                });

                renderQuickFilters($quickFilter, $productGrid);
            });
        }
    };
}

export const initInScope = createQuickFilter().initInScope;