"use strict";

export function initInScope($scope) {
    $scope.find('.js-geo-location').on('click', function (evt) {
        console.log('click');
        evt.preventDefault();
        let $target = $($(this).data('target'));
        // $target.val('48.2081 16.3713').trigger('change');
        // console.log($target.val());

        navigator.geolocation.getCurrentPosition(function(position) {
            console.log('currentPosition', position.coords.latitude, position.coords.longitude);

            $target.val(position.coords.latitude + ',' + position.coords.longitude).trigger('change');
            console.log('triggered change');
            console.log($target.val());
        });
    });
}