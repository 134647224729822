"use strict";


export function scrollTo ($target, offset = 0) {
    if (!$target || !$target.length) {
        console.warn("Can't scroll to '" + $target + "'. Target element not found");
        return;
    }

    let duration = Math.abs($target.offset().top - window.scrollY) / 8 + 500;


    let newOffset = $target.offset().top - offset;

    $('html, body').stop().animate({
        scrollTop: newOffset
    }, duration, 'swing');
}

export function focus ($target) {
    if (typeof $target.attr('tabindex') == 'undefined') {
        $target.addClass('hide-focus');
        $target.attr('tabindex', '-1');
    }
    $target.focus();
}

export function initInScope ($scope) {
    $scope.find('.js-scroll-to').on('click', function (evt) {
        evt.preventDefault();
        let offset = $(this).data('scroll-offset');
        let targetSelector = $(this).data('scroll-target') || $(this).attr('href');
        if(targetSelector) {
            let $target = $(targetSelector);

            scrollTo($target, offset, function () {
                focus($target);
            });
        }
    });
}
