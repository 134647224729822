import 'slick-carousel';

export function initInScope ($scope) {
    let $sliders = $scope.find('.js-wysiwyg-slider');

    $sliders.each(function () {
        let $sliderWrapper = $(this).closest('.js-wysiwyg-slider__wrapper');

        $(this).slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            dots: true,
            fade: false,
            draggable: true,
            centerMode: true,
            centerPadding: '73px',
            infinite: true,
            prevArrow: $sliderWrapper.find('.slider__arrow.arrow-prev'),
            nextArrow: $sliderWrapper.find('.slider__arrow.arrow-next'),
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        centerMode: false,
                        centerPadding: '0px',
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        centerMode: true,
                        centerPadding: '70px',
                    }
                },
                {
                    breakpoint: 575,
                    settings: {
                        centerMode: false,
                        centerPadding: '0px',
                    }
                }
            ]
        });
    });
}
