"use strict";

export function initInScope($scope) {
    let $dealerMap = $scope.find('.js-track-dealer-map');
    let $geoLocationLink = $scope.find('.js-geo-location');
    let $locationInput = $dealerMap.find('#location');

    $locationInput.on('change', function () {
        $dealerMap.removeClass('js-track-dealer-map--geolocation');
    });

    $geoLocationLink.on('click', function () {
        $dealerMap.addClass('js-track-dealer-map--geolocation');
    });


    $dealerMap.find('input, select').on('change programmaticChange', function () {
        let category = 'Händlersuche';
        let action;

        if($dealerMap.hasClass('js-track-dealer-map--geolocation')) {
            action = 'Standort automatisch ermittelt ' + $dealerMap.find('#radius option:selected').val() + ' km';
        } else {
            action = $dealerMap.find('#location').val() + ' ' + $dealerMap.find('#radius option:selected').val() + ' km';
        }

        let label = [];
        $dealerMap.find('input:checked').each(function() {
            label.push($(this).attr('name'));
        });


        //console.log(category + action + label);
        dataLayerPush(category, action, label);
    });


    function dataLayerPush(category, action, label) {
        if(window.dataLayer) {
            dataLayer.push({
                'event': 'genericGtmEvent',
                'genericEventCategory': category,
                'genericEventAction': action,
                'genericEventLabel': label
            });
        }
    }
}
