import 'slick-carousel';

export function initInScope ($scope) {
    let $sliders = $scope.find('.js-product-slider');

    $sliders.each(function () {
        let $sliderWrapper = $(this).closest('.js-product-slider__wrapper');

        $(this).slick({
            slidesToShow: 5,
            slidesToScroll: 2,
            arrows: true,
            dots: true,
            fade: false,
            draggable: true,
            infinite:false,
            prevArrow: $sliderWrapper.find('.slider__arrow.arrow-prev'),
            nextArrow: $sliderWrapper.find('.slider__arrow.arrow-next'),
            responsive: [{
                breakpoint: 1199,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1.3,
                    slidesToScroll: 1,
                }
            }]
        });
    });
}
