"use strict";

import {translate} from '@elements/translations';

export function initInScope($scope) {

    window.addEventListener("cf_consent", callbackFnc);
    window.addEventListener("cf_consent_loaded", callbackFnc);

    function callbackFnc(e) {
        let consent = e.detail || null;
        if (consent && consent.functional) {
            var headHandler = document.querySelector('head');
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://cdn.chatwerk.de/1.0.0/chatwerk-loader.min.js.gz';
            script.onload = function () {
                var chatwerkLoaderConfig = {
                    "inboxConfig": {
                        "organizationQuery": {
                            "id": "elIsQcmCIF"
                        },
                        "opened": true,
                        "language": _config.lang,
                        "dictionaries": {
                            "de": {
                                "welcomeMessage": translate('live-chat.info-box')
                            }
                        },
                        "theme": {
                            "circleIconBackground": "#1fa22e",
                            "headerBackgroundColor": "#1fa22e"
                        }
                    }
                };

                chatwerkLoader.initialize(chatwerkLoaderConfig);
            };
            headHandler.appendChild(script);
        }
    }
}