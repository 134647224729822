"use strict";

//import {on} from "@elements/dom-utils";
import fetch from "@elements/fetch";
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    let promiseChain = Promise.resolve(import('lightgallery.js'));

    promiseChain = promiseChain.then(() => {
        Promise.resolve(import('lg-thumbnail.js'))
    });

    promiseChain.then(() => {
        let galleryItems = $('.js-lightbox__dynamic');
        galleryItems.each(function (lightboxDynItem) {
            const lightboxClickHandler = (evt, lightboxDynItem) => {
                evt.preventDefault();

                let url = jQuery(this).attr('data-url');

                let request = fetch(url, {
                    method: 'get'
                });

                request.then(response => response.json())
                    .then(result => {
                        if (result.success) {
                            lightGallery(this, {
                                thumbnail:true,
                                dynamic: true,
                                dynamicEl: result.content,
                                index: lightboxDynItem
                            });
                        }
                    }).catch(e => {
                    console.warn(e);
                });
            };

            this.addEventListener('click', function(evt) {
                lightboxClickHandler(evt, lightboxDynItem)
            });

        });
    });
}