"use strict";

let filterIsCollapsed;

export function initInScope($scope) {
    $scope.find('.js-toggle-text-on-collapse').each(function () {
        let $item = $(this);
        let target = $item.attr('href') || $item.attr('data-target');
        let $target = $(target);
        let $textCollapsed = $item.find('.js-toggle-text-on-collapse__collapsed');
        let $textExpanded = $item.find('.js-toggle-text-on-collapse__expanded');
        let filterCollapse = !!$item.attr('data-filter-collapse');

        if (filterCollapse === true && filterIsCollapsed === true) {
            $target.collapse('show');
        }

        $target.on('show.bs.collapse', function () {
            $textCollapsed.attr('aria-hidden', 'true').attr('hidden', 'hidden');
            $textExpanded.attr('aria-hidden', 'false').attr('hidden', null);
            filterIsCollapsed = true;
        });

        $target.on('hide.bs.collapse', function () {
            $textExpanded.attr('aria-hidden', 'true').attr('hidden', 'hidden');
            $textCollapsed.attr('aria-hidden', 'false').attr('hidden', null);
            filterIsCollapsed = false;
        });
    });
}
