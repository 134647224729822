import 'slick-carousel';

import {loadImg} from '@elements/lazy-img';

export function initInScope ($scope) {
    let $sliders = $scope.find('.js-slider');

    $sliders.each(function () {
        let $slider = $(this);
        let $sliderWrapper = $(this).closest('.js-slider__wrapper');
        let slideCount = $slider.attr('data-slider-slide-count') || false;
        let preloadBg = $slider.attr('data-slider-preload-backgrounds') || false;

        $slider.slick({
            slidesToShow: $slider.attr('data-slider-slides-to-show') || 1,
            slidesToScroll: $slider.attr('data-slider-slides-to-scroll') || 1,
            arrows: true,
            dots: true,
            fade: true,
            centerMode: true,
            speed: 800,
            cssEase: 'linear',
            infinite:false,
            prevArrow: $sliderWrapper.find('.slider__arrow.arrow-prev'),
            nextArrow: $sliderWrapper.find('.slider__arrow.arrow-next')
        });

        if(slideCount && $sliderWrapper.find('.js-slider__slide-count--current').length > 0 && $sliderWrapper.find('.js-slider__slide-count--sum').length > 0 ) {
            $slider.on('afterChange', function(event, slick, currentSlide, nextSlide) {
                $sliderWrapper.find('.js-slider__slide-count--sum').text(slick.$slides.length );
                $sliderWrapper.find('.js-slider__slide-count--current').text(currentSlide + 1);
            });
        }

        if(preloadBg) {
            $slider.on('init', function(event, slick){
                preloadBackgrounds()
            });
        }

    });
}

function preloadBackgrounds($slider){
    let $nextImg = $slider.find('.js-lazy-img--bg');
    loadImg($nextImg);
}