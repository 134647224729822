const selectors = {
    banner: '.js-affix-banner',
    playStateButton: '.js-banner-play-state',
    iconPlay: '.js-banner-play-state__play-icon',
    iconPause: '.js-banner-play-state__pause-icon'
}

let isCurrentlyPaused = false;

export function init() {
    $(selectors.playStateButton).on('click', function () {
        togglePlayState();
        updatePlayState();
        console.log("toggeled state and updated banner")
    })

    function togglePlayState() {
        isCurrentlyPaused = !isCurrentlyPaused;
    }

    function updatePlayState() {
        if(isCurrentlyPaused) {
            $(selectors.iconPlay).attr('hidden', false);
            $(selectors.iconPause).attr('hidden', true);
            $(selectors.banner).addClass('is-paused'); // add a class to the banner to pause the animation
        } else {
            $(selectors.iconPlay).attr('hidden', true);
            $(selectors.iconPause).attr('hidden', false);
            $(selectors.banner).removeClass('is-paused'); // add a class to the banner to pause the animation
        }
    }
}