"use strict";
import initModulesInScope from "@elements/init-modules-in-scope";

export function initInScope($scope) {
    // radios are in result of ajax form and js checkout is outside of it
    let $wrapper = $scope.find('.js-checkout');
    let $continueBtn = $wrapper.find('.js-checkout__continue-btn');
    let $radios = $scope.find('.js-checkout__radio');
    let $ajaxForm = $wrapper.find('.js-ajax-form');
    console.log("scope", $scope);
    console.log("radios", $radios);

    $radios.each(function (index) {
        let $radio = $(this);
        $radio.on('change', function () {
            let $wrapperScoped = $(this).closest('.js-checkout');
            let $continueBtnScoped = $wrapperScoped.find('.js-checkout__continue-btn');

            $continueBtnScoped.removeAttr('disabled');
        });
    });

    $ajaxForm.on('success.ajax-form', function (e, data) {
        $continueBtn.attr('disabled', true);
    });
}