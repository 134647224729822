"use strict";

const selectors = {
    base: '.js-typehead-interactive-position',
    typeheadFlyout: '.tt-menu'
}
export function initInScope($scope) {
    $scope.find(selectors.base).each(function () {
        let $wrapper = $(this);
        let $typeheadFlyout = $wrapper.find(selectors.typeheadFlyout);

        function handleScroll() {
            if($wrapper.offset().top < window.scrollY + window.innerHeight / 2) {
                $typeheadFlyout.removeClass('tt-menu--top');
                console.log("set top")
            } else {
                $typeheadFlyout.addClass('tt-menu--top');
                console.log("unset top")
            }
        }

        window.addEventListener('scroll', handleScroll);
        handleScroll();
    });
}