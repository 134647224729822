import loadScript from '@elements/load-script';
import {getConfigValue} from '@elements/config-utils';

export function initInScope ($scope) {
    loadScript('https://www.google.com/recaptcha/api.js?render='+ getConfigValue('recaptchaSitekey')).then(() => {
        grecaptcha.ready(function() {
            let recaptchas = document.querySelectorAll('.js-recaptcha');


            recaptchas.forEach(recaptcha => {
                let recaptchaAction =  recaptcha.getAttribute('data-recaptcha-action');
                // do request for recaptcha token
                // response is promise with passed token
                grecaptcha.execute(getConfigValue('recaptchaSitekey'), {action:recaptchaAction})
                    .then(function(token) {
                        console.log("captcha ready");
                        // add token value to form
                        console.log(recaptcha.querySelector('.js-recaptcha__response')); 
                        recaptcha.querySelector('.js-recaptcha__response').value = token;
                    });
            });
        });
    }).catch(console.error);
}
