"use strict";

export function initInScope($scope) {
    let $modal = $scope.find('.js-focus-on-modal-show');
    $modal.on('show.bs.modal', function () {
        let $this = $(this);

        //due to typeahead bug
        setTimeout(function() {
            $this.find('.js-focus-on-modal-show__input').focus();
        }, 500);

    })
}