export function initInScope ($scope) {
    let $container = $scope.find('.js-article-item__amount');

    $container.each(function(container) {
        let $input = $(this).find('.js-article-item__amount-input');
        let url = $(this).data('article-item-request-url');

        $input.on('change', function () {
            $.ajax({
                url: url,
                type: 'GET',
                data: {
                    value: $input.val()
                }
            });
        });
    });
}