"use strict";

import "../libs/elevateZoom";

export function initInScope($scope) {
    if (matchMedia('(min-width: 768px)').matches) {
        let $zoomImg = $scope.find('.js-img-zoom');
        zoom($zoomImg);
    }
}

export function zoom($img) {
    $img.ezPlus({
        zoomType: "lens",
        lensShape : "round",
        lensSize: 150,
        cursor: 'pointer',
        borderColour: getComputedStyle(document.documentElement).getPropertyValue('--color-primary') || '#000',
        containLensZoom: true
    });
}