import {isElementInViewport} from '@elements/viewport-utils'
import {initAjaxFormMap, apiLoaded} from "@elements/ajax-form-map/ajax-form-google";
import {addMarker, setActiveMarkerStyle, setActiveClusterStyle, setDefaultClusterStyle, setDefaultMarkerStyle} from "@elements/google-map";

console.log("ajax-form-map.js");

export function initInScope($scope) {
    let $maps = $scope.find('.js-ajax-form-map__map');

    window.addEventListener("cf_consent", callbackFnc);
    window.addEventListener("cf_consent_loaded", callbackFnc);

    function callbackFnc(e) {
        console.log("callbackfnc")
        let consent = e.detail || null;
        let elements = document.querySelectorAll(".js-cookie-first--map");
        elements.forEach(function(el) {
            let fetchEverythingFunction = null;

            // no consent yet or advertising not accepted, hide div
            if (!consent || !consent.functional) {
                el.style.display = 'block';
            } else {
                el.style.display = 'none';
                if ($maps && $maps.length) {
                    $maps.each(function (index, element) {
                        let $container = $(element).closest('.js-ajax-form-map');
                        let $mapCanvas = $(element).find('.js-ajax-form-map__map__canvas');

                        apiLoaded().then(function () {
                            const poiStyles = {
                                default: {
                                    default: {
                                        url: '/static/build/img/map/poi.svg',
                                        size: new google.maps.Size(34, 50),
                                        origin: new google.maps.Point(0, 0),
                                        anchor: new google.maps.Point(17, 50)
                                    },
                                    active: {
                                        url: '/static/build/img/map/poi-active.svg',
                                        size: new google.maps.Size(34, 50),
                                        origin: new google.maps.Point(0, 0),
                                        anchor: new google.maps.Point(17, 50)
                                    }
                                }
                            };

                            const infoBoxOptions = {
                                pixelOffset: new google.maps.Size(-158, -50),
                                boxStyle: {width: "300px", background: "#fff", padding: "20px"},
                                boxClass: "infobox",
                                closeBoxURL: "/static/img/map/close.svg"
                            };

                            let clusteringOptions = {
                                default: {
                                    styles: [{
                                        height: 46,
                                        url: "/static/build/img/map/marker-cluster.svg",
                                        width: 46,
                                        textSize: 20,
                                        textColor: "#FFF"
                                    }]
                                },
                                active: {
                                    styles: [{
                                        height: 46,
                                        url: "/static/build/img/map/marker-cluster.svg",
                                        width: 46,
                                        textSize: 20,
                                        textColor: "#FFF"
                                    }]
                                }
                            };

                            let options = $(element).data('google-map-options');

                            let zoomControlOptions = {
                                zoomControl: true,
                                zoomControlOptions: {
                                    position: google.maps.ControlPosition.RIGHT_CENTER,
                                }
                            };

                            options = {
                                ...zoomControlOptions,
                                ...options,
                            };

                            //init ajax form map
                            let ajaxFormMapObj = initAjaxFormMap($scope.find('.js-ajax-form-map'), undefined, {
                                element: $mapCanvas[0],
                                mapOptions: options,
                                poiStyles,
                                infoBoxOptions,
                                clustering: true,
                                clusteringOptions,
                                onActivateMarker: (marker, mapObj) => onActivateMarker($container, marker, mapObj),
                                onDeactivateMarker: (marker, mapObj) => onDeactivateMarker($container, marker, mapObj)
                            });

                            fetchEverythingFunction = ajaxFormMapObj.formApi.submit;
                            initMapItems(ajaxFormMapObj.mapObj, $container);
                        });

                        let fetchingPois = false;
                        let submitButton = $container.find('.js-dealersearch-submit'); // to get rid of duplicate submit
                        submitButton.on('click', function(e) {
                            if(!fetchingPois) {
                                fetchingPois = true;
                                fetchEverythingFunction();
                            }
                        });

                        let inputs = $container.find('.js-dealersearch-input');
                        let isInputFocused = false;
                        inputs.on('focus', function() {
                            isInputFocused = true;
                        });

                        inputs.on('blur', function() {
                            isInputFocused = false;
                        });

                        inputs.on('keyup', function(e) {
                            if (e.keyCode === 13 && isInputFocused) {
                                if(!fetchingPois) {
                                    fetchingPois = true;
                                    fetchEverythingFunction();
                                }
                            }
                        });

                        let selects = $container.find('.js-dealersearch-select');
                        console.log("selects", selects);

                        selects.on('change', function(e) {
                                    inputs.each(function() {
                                        $(this).val('');
                                });
                        });

                        let location = $container.find('.js-dealersearch-geolocation');

                        location.on('click', function(evt) {
                            evt.preventDefault();
                            let $target = $($(this).data('target'));
                            console.log("click", $target);

                            $target.val('48.2081 16.3713');
                            // selects.each(function() {
                            //     $(this).val('');
                            // });
                            console.log($target.val());
                            if(!fetchingPois) {
                                fetchingPois = true;
                                fetchEverythingFunction();
                            }
                        });

                        $container.on('success.ajax-form', function(e) {
                            console.log("success.ajax-form", e)
                            fetchingPois = false;
                        });

                        // get the form element
                        let $form = $container.find('.js-ajax-form-map__form');
                        // add a submit event listener
                        $form.on('submit', function(e) {
                            e.preventDefault();
                            e.stopImmediatePropagation();
                            console.log("prevented default submit on ajaxform")
                        });
                    });
                }
            }
        });
    }
}


function onActivateMarker($container, marker, mapObj) {
    if (marker.dataId) {
        let $item = $container.find(`.js-ajax-form-map__item[data-id="${marker.dataId}"]`);
        $item.addClass('is-active');

        $item.find('.collapse').collapse('show');

        if ($item && $item.length && !isElementInViewport($item)) {
            let itemOffset = $item.offset().top;
            let scrollPosition = window.scrollY;

            if (matchMedia('(min-width: 768px)').matches) {
                $('html, body').stop().animate({
                    scrollTop: itemOffset > scrollPosition
                        ? itemOffset - window.innerHeight + $item.height() + 160
                        : itemOffset - 160
                }, 200);
            } else {
                $('html, body').stop().animate({
                    scrollTop: itemOffset - 60
                }, 200);
            }
        }
    }
}

function onDeactivateMarker($container, marker, mapObj) {
    // closeInfoBoxByMarker(marker, mapObj);
    if (marker.dataId) {
        $container.find(`.js-ajax-form-map__item[data-id="${marker.dataId}"]`).removeClass('is-active');
        $container.find(`.js-ajax-form-map__item[data-id="${marker.dataId}"]`).find('.collapse').collapse('hide');
    }
}

function initMapItems(mapObj, $container) {
    $container.find('.js-ajax-form-map__item').each(function () {
        let $item = $(this);

        $item.on('mouseenter', function () {
            let id = $(this).data('id');
            let activeMarker = mapObj.marker.filter(marker =>
                !!marker.dataId && marker.dataId.toString() === id.toString())[0];

            if (activeMarker) {
                setActiveMarkerStyle(activeMarker, mapObj);


                if (mapObj.clusteringPromise) {
                    mapObj.clusteringPromise.then((markerCluster) => {
                        let clusters = markerCluster.getClusters();

                        clusters.forEach(cluster => setDefaultClusterStyle(cluster, mapObj));

                        let activeCluster = clusters.find(cluster =>
                            cluster.getMarkers().find(marker => marker === activeMarker)
                        );

                        setActiveClusterStyle(activeCluster, mapObj);
                    });
                }
            }
        }).on('mouseleave', function () {
            let id = $(this).data('id');
            let activeMarker = mapObj.marker.filter(marker =>
                !!marker.dataId && marker.dataId.toString() === id.toString())[0];

            if (activeMarker) {
                setDefaultMarkerStyle(activeMarker, mapObj);
            }

            if (mapObj.clusteringPromise) {
                mapObj.clusteringPromise.then((markerCluster) => {
                    markerCluster.getClusters().forEach(
                        cluster => setDefaultClusterStyle(cluster, mapObj)
                    );
                });
            }
        });
    });
}