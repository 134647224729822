"use strict";

export function initInScope($scope) {
    let $input = $scope.find('.js-geolocation__input');
    let $button = $scope.find('.js-geolocation__button');

    $button.on('click', function () {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showCity);
        }
    });

    function showCity(position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        // Make a request to a Geocoding API (e.g. Google Maps Geocoding API)
        // const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBQnopln_NpDA-aXYYrScl7_K0cFYCRAAs`;
        const url = `/static/stylelab-ajax/google-geocoding-test.json`;

        $.ajax({
            url: url,
            type: "GET",
            dataType: "json",
            success: function (data) {
                let addressName = data.results[0].formatted_address;
                $input.val(addressName);
                $input.addClass('has-value');
                $input.closest('.form-group').addClass('has-value');
            }
        });

        // fetch(url)
        //     .then((response) => response.json())
        //     .then((data) => {
        //         // Parse the city name from the API response
        //         const city = data.results[0].address_components.find((component) =>
        //             component.types.includes("locality")
        //         ).long_name;
        //
        //         console.log(`Your city is ${city}.`);
        //     })
        //     .catch((error) => console.log(error));
    }
}