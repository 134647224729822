"use strict";

export function initInScope($scope) {
    let $hotspots = $scope.find('.js-hotspot');

    $scope.find('.js-hotspot__toggle').on('click', function () {
        let $hotspot = $(this).closest('.js-hotspot');

        if(!$hotspot.hasClass('show')) {
            $hotspots.each(function () {
                $(this).removeClass('show');
            });
            $hotspot.addClass('show');

            $hotspot.find('.hotspot__icon--close').on('click', function () {
                $hotspot.removeClass('show');
            });

            $('body').on('click', bodyClickHandler);
        } else {
            $hotspot.removeClass('show');
            $('body').off('click', bodyClickHandler);
        }
    });

    if(matchMedia('(min-width: 768px)').matches) {
        $(window).on('load', function () {
            $hotspots.each(function () {
                let $this = $(this),
                    $detail = $(this).find('.hotspot__content'),
                    container = $this.closest('.hotspot__wrapper');

                if($this.position().top < (container.outerHeight()/2)){
                    $detail.addClass('hotspot--content-bottom');
                }
            });
        });
    }

}

function bodyClickHandler (evt) {
    if ($(evt.target).parents('.js-hotspot').length < 1) {
        evt.preventDefault();
        $('.js-hotspot').removeClass('show');

        $('body').off('click', bodyClickHandler);
    }
}