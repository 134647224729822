"use strict";

export function initInScope($scope) {
    $scope.find('.js-expand-certificates__btn').on('click', function (e) {
        e.preventDefault();
        let $certificate = $(this).closest('.js-expand-certificates');

        if(!$certificate.hasClass('expanded')) {
            $certificate.addClass('expanded');
        } else {
            $certificate.removeClass('expanded');
        }
    });

}
