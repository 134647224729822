"use strict";

import fetch from '@elements/fetch';
import asyncAppend from "@elements/async-append";

export function initInScope($scope) {

    let $loadMore = $scope.find('.js-load-more');

    $loadMore.on('click', () => {

        let url = $loadMore.attr('data-url');
        let $targetEl = $($loadMore.attr('data-target'));
        let $loadingEl = $scope.find('.js-load-more__loading');
        let $notificationsEl = $($loadMore.attr('data-notifications'));


        if(url === 'undefined' || url === null || url === '') {
            console.warn("async-request: data-url is not defined");
        }

        asyncAppend({
            $target: $targetEl,
            $loading: $loadingEl,
            $notifications: $notificationsEl
        }, fetch(url, {
            method : 'POST'
        }));
    });
}
