"use strict";


export function initInScope($scope) {
    let iframes = $scope.find('.js-cookiefirst-iframe');
    if(iframes){
        iframes.each(function() {
            let category = $(this).data('cookiefirstCategory');
            let iframe = $(this);

            //not pretty, but CookieFirst is not initialized yet and does not provide an event for this
            setTimeout(function() {
                const consent = CookieFirst.consent;
                if(consent && consent[category] === true){
                    iframe.attr("src",iframe.data('src'));
                    iframe.removeAttr("data-src");
                }
            }, 1000);
        });
    }

    //really, really bad hack. I am sorry, it wasn't possible in any other way, due to the lightbox-implementation -.-'
    $scope.on('click', '.js-cookie-first__accept-category', function(e) {
        e.preventDefault();
        let category = $(this).data('category');

        CookieFirst.acceptCategory(category);
    });

}
