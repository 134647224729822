"use strict"

export function initInScope ($scope) {
    let $wrapper = $scope.find('.js-toggle-password-visibility');
    let $onToggle = $wrapper.find('.js-toggle-password-visibility__on');
    let $offToggle = $wrapper.find('.js-toggle-password-visibility__off');
    let $input = $wrapper.find('.js-toggle-password-visibility__input');

    $onToggle.on('click', function (evt) {
        evt.preventDefault();
        $(this).addClass("d-none");
        $(this).closest($wrapper).find($offToggle).removeClass("d-none");
        $(this).closest($wrapper).find($input).attr('type', 'text');
    });

    $offToggle.on('click', function (evt) {
        evt.preventDefault();
        $(this).addClass("d-none");
        $(this).closest($wrapper).find($onToggle).removeClass("d-none");
        $(this).closest($wrapper).find($input).attr('type', 'password');
    });
}