"use strict";

export function initInScope ($scope) {
    let $wrapper = $scope.find('.js-interests-accordion');
    let $optionWrapper = $scope.find('.js-interests-accordion__option-wrapper');
    let $indicator = $scope.find('.js-interests-accordion__indicator');
    let $options = $scope.find('.js-interests-accordion__option');
    let $accordionLink = $scope.find('.js-interests-accordion__header-link');

    $options.each(function() {
       let $option = $(this);
       $option.on('change', function() {
           setIndicatorActive($option);
       });
    });

    function setIndicatorActive ($clickedCheckbox) {
        let $indicatorInput = $clickedCheckbox.closest($wrapper).find($indicator).find('input');
        let $optionWrapperClickedItem = $clickedCheckbox.closest($optionWrapper);
        let $activeLink = $clickedCheckbox.closest($wrapper).find($accordionLink);

        // check if there is a checked input in the $wrapper
        let checkedInputs = $optionWrapperClickedItem.find('input:checked');
        if (checkedInputs.length > 0) {
            $indicatorInput.attr('checked', true);
            $clickedCheckbox.addClass('is-checked');
            $activeLink.addClass('is-active');
        } else {
            $indicatorInput.attr('checked', false);
            $clickedCheckbox.removeClass('is-checked');
            $activeLink.removeClass('is-active');
        }
    }
}