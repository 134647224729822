"use strict";
import {isValid} from '@elements/parsley-bootstrap-validation'


export function initInScope ($scope) {
    let $forms = $scope.find('.js-step-form');

    $forms.each(function () {
        let $form = $(this);
        let $steps = $form.find('.js-step-form__step');
        let $stepNumber = $form.find('.js-step-form__current-step-number');

        // prevent form submit on keyboard enter press
        $form.keypress(function(event) {
            if (event.keyCode === 13) {
                event.preventDefault();
                if (curIndex() >= $steps.length - 1) {
                    $form.submit();
                } else {
                    $form.find('.js-step-form__next').click();
                }
                return false;
            }
        });

        $steps.each(function (index) {
            $(this).find(':input').attr('data-parsley-group', 'block-' + index);
        });
        navigateTo(0); // Start at the beginning

        $form.find('.js-step-form__next').on('click', function(evt) {
            evt.preventDefault();

            $form = $(this).closest('.js-step-form');
            isValid($form);

            $form.parsley().whenValidate({
                group: 'block-' + curIndex()
            }).done(function() {
                if(isValid($form)){
                    navigateTo(curIndex() + 1);
                }
            });
        });

        $form.find('.js-step-form__prev').click(function(evt) {
            evt.preventDefault();
            navigateTo(curIndex() - 1);
        });

        function curIndex() {
            return $steps.index($steps.filter('.is-current'));
        }

        function navigateTo(index) {
            $steps.removeClass('is-current').eq(index).addClass('is-current');
            $stepNumber.html(index + 1);

            if(index !== 0) {
                $form.find('.js-step-form__prev').attr('hidden', false)
            } else {
                $form.find('.js-step-form__prev').attr('hidden', true)
            }

            if(index >= $steps.length - 1) {
                $form.find('.js-step-form__next').attr('hidden', true);
                $form.find('.js-step-form__submit').attr('hidden', false);
            } else {
                $form.find('.js-step-form__next').attr('hidden', false);
                $form.find('.js-step-form__submit').attr('hidden', true);
            }
        }
    });
}
