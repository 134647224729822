"use strict";
import initModulesInScope from "@elements/init-modules-in-scope";

export function initInScope($scope) {
    $scope.find('.js-comparison-area').each(function () {
        let $comparisonArea = $(this);
        let $comparisonAreaWrapper = $comparisonArea.closest('.js-comparison-area__wrapper');
        let $table = $comparisonArea.find('.js-comparison-area__table');
        let $inputs = $comparisonArea.find('.js-comparison-area__input');
        let $searchIcons = $comparisonArea.find('.js-comparison-area__search-icon');
        let $selects = $comparisonArea.find('.js-comparison-area__select');
        let $addItemBtn = $comparisonArea.find('.js-comparison-area__add-item');
        let $removeItemBtn = $comparisonArea.find('.js-comparison-area__remove-item');
        let $loadingOverlay = $comparisonArea.find('.js-comparison-area__loading');
        let $differencesToggle = $comparisonArea.find('.js-comparison-area__differences-toggle');
        let $goLeftBtn = $comparisonArea.find('.js-comparison-area__go-left');
        let $addItemMobileBtn = $comparisonArea.find('.js-comparison-area__add-item-mobile');
        let $products = $comparisonArea.find('.js-comparison-area__products');

        calcColWidth($table, $comparisonArea);

        $addItemBtn.on('click', function() {
            let url = $comparisonArea.data('actionItem');
            loadContent(url, $comparisonAreaWrapper, $loadingOverlay, $table);
        });

        $differencesToggle.off('change').on('change', function() {
            let url = $comparisonArea.data('actionDifferencesToggle');
            let isToggled = $(this).find('.js-comparison-area__differences-toggle-input').is(':checked');
            loadContent(url, $table, $loadingOverlay, $table, { toggled: isToggled });
        });

        $removeItemBtn.on('click', function() {
            let urlRemove = $comparisonArea.data('actionRemoveItem');
            let urlOverview = $comparisonArea.data('actionOverview');

            // First, make the remove request
            $.ajax({
                url: urlRemove,
                type: 'GET',
                dataType: 'json',
                success: function(response) {
                    if (response.success) {
                        // After the remove request is successful, make the overview request
                        $.ajax({
                            url: urlOverview,
                            type: 'GET',
                            dataType: 'json',
                            success: function(response) {
                                if (response.success) {
                                    $comparisonAreaWrapper.html(response.html);
                                    calcColWidth($table, $comparisonAreaWrapper);
                                    initModulesInScope();
                                }
                            }
                        });
                    }
                }
            });
        });

        $inputs.each(function() {
            $(this).on('keyup', function(e) {
                if (e.key === 'Enter') {
                    let $item = $(this).closest('.js-comparison-area__item');
                    let url = $item.data('actionInput');
                    let selectedValue = $(this).val();
                    let selectedId = $item.data('wishlistId');
                    loadContent(url, $comparisonAreaWrapper, $loadingOverlay, $table, { inputValue: selectedValue, id: selectedId });
                }
            });
        });

        $searchIcons.each(function() {
            $(this).on('click', function() {
                let $item = $(this).closest('.js-comparison-area__item');
                let url = $item.data('actionInput');
                let selectedValue = $item.find('.js-comparison-area__input').val();
                let selectedId = $item.data('wishlistId');

                if (selectedValue.trim() !== '') {
                    loadContent(url, $comparisonAreaWrapper, $loadingOverlay, $table, { inputValue: selectedValue, id: selectedId });
                }
            });
        });

        $selects.each(function() {
            $(this).on('change', function() {
                let $item = $(this).closest('.js-comparison-area__item');
                let url = $item.data('actionSelect');
                let selectedValue = $(this).val();
                loadContent(url, $comparisonAreaWrapper, $loadingOverlay, $table, { selectedValue: selectedValue });
            });
        });

        $goLeftBtn.on('click', function() {
            let url = $comparisonArea.data('actionGoLeft');
            $products.addClass('scroll-left');
            $products.on('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function() {
                loadContent(url, $comparisonAreaWrapper, $loadingOverlay, $table);
            });
        });

        $addItemMobileBtn.on('click', function() {
            let url = $comparisonArea.data('actionItem');
            $products.addClass('scroll-right');
            $products.on('transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd', function() {
                loadContent(url, $comparisonAreaWrapper, $loadingOverlay, $table);
            });
        });
    });
}

function calcColWidth($table, $comparisonArea) {
    let $comparisonAreaItems = $comparisonArea.find('.js-comparison-area__item');
    let $tableCells = $table.find('td');
    let columnWidth = $comparisonAreaItems.first().outerWidth();
    $tableCells.each(function() {
        $(this).css('width', columnWidth);
    });
}

function loadContent(url, $target, $loadingOverlay, $table, data = {}) {
    $loadingOverlay.attr('hidden', false);
    $.ajax({
        url: url,
        type: 'GET',
        dataType: 'json',
        data: data,
        success: function(response) {
            if (response.success) {
                $loadingOverlay.attr('hidden', true);
                $target.html(response.html);
                if($table) {
                    calcColWidth($table, $target);
                }
                initModulesInScope();
            } else {
                $target.html('<p>No product found</p>');
            }
        }
    });
}