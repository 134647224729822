"use strict";

import {translate} from '@elements/translations';
import {loadParsley, options} from '@elements/parsley-bootstrap-validation';

export function initInScope ($scope) {
    let $forms = $scope.find('.js-custom-validation');

    loadParsley().then(function () {
        $forms.parsley(options);

        // checks file size of every single selected file
        if (!Parsley.hasValidator('maxFileSize')) {
            Parsley.addValidator('maxFileSize', {
                validateString: function(_value, maxSize, parsleyInstance) {
                    let files = parsleyInstance.$element[0].files;
                    for(let x = 0; x < files.length; x++) {
                        if(files[x].size >  maxSize * 1024) {
                            return false;
                        }
                    }
                },
                messages: {
                    de: translate('maxFileSizeError') + ' %s kb.',
                    en: translate('maxFileSizeError') + ' %s kb.'
                }
            });
        }

        if (!Parsley.hasValidator('maxFileUpload')) {
            Parsley.addValidator('maxFileUpload', {
                validateString: function(_value, maxUpload, parsleyInstance) {
                    let files = parsleyInstance.$element[0].files;
                    return files.length <= maxUpload
                },
                messages: {
                    de: translate('maxFileUploadError') + ' %s',
                    en: translate('maxFileUploadError') + ' %s'
                }
            });
        }

        if (!Parsley.hasValidator('fileAccept')) {
            Parsley.addValidator('fileAccept', {
                validateString: function(value, requirements) {
                    let fileAcceptArray = requirements.split(',');
                    let fileExtension = value.split('.').pop();
                    let isInArray = fileAcceptArray.filter(item => item.includes(fileExtension.toLowerCase()));

                    if(isInArray.length > 0) {
                        return true;
                    } else {
                        return false;
                    }
                },
                messages: {
                    de: translate('fileAccept') + ' %s',
                    en: translate('fileAccept') + ' %s'
                }
            });
        }

    });
}
