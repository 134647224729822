"use strict";

export function initInScope ($scope) {
    let $slider = $scope.find('.js-range-slider');

    import('nouislider').then(function (noUiSlider) {
        $slider.each(function () {
            let $container = $(this);
            let slider = $container.find('.js-range-slider__slider')[0];
            let $minInput = $container.find('.js-range-slider__min');
            let $maxInput = $container.find('.js-range-slider__max');
            let $minTextInput = $container.find('.js-range-slider__min-text');
            let $maxTextInput = $container.find('.js-range-slider__max-text');
            let isProductGridSlider = $container.hasClass('js-product-grid__slider') || false;
            

            let min = parseInt($container.attr('data-range-slider-min'));
            let max = parseInt($container.attr('data-range-slider-max'));

            noUiSlider.create(slider, {
                start: [
                    $minInput.val() || min,
                    $maxInput.val() || max
                ],
                connect: true,
                range: {
                    'min': parseInt(min),
                    'max': parseInt(max)
                }
            });

            if(isProductGridSlider) {
                /* product-grid.input-changed or changed to react to changes from the product grid */
                $minInput.on('product-grid.input-changed', function () {
                    setMin(this.value !== '' ? Math.floor(this.value) : min);
                });

                /* product-grid.input-changed or changed to react to changes from the product grid */
                $maxInput.on('product-grid.input-changed', function () {
                    setMax(this.value !== '' ? Math.floor(this.value) : max);
                });
            }

            slider.noUiSlider.on('update', function( values, handle ) {
                let value = +values[handle];
                if ( handle ) {
                    setMaxText(parseInt(value));
                } else {
                    setMinText(parseInt(value));
                }
            });

            slider.noUiSlider.on('change', function ( values, handle ) {
                if ( handle ) {
                    setMax(parseInt(values[handle]));
                    $maxInput.trigger('change');
                } else {
                    setMin(parseInt(values[handle]));
                    $minInput.trigger('change');
                }
            });

            function setMin(value) {
                value = +value || min;
                value = parseInt(value);
                $minInput.val(+value === min ? null: value);
                slider.noUiSlider.set([value, null]);
                setMinText(value);
            }

            function setMinText(value) {
                value = +value || min;
                let text = value.toLocaleString(_config.lang);

                $minTextInput.text(text);
                $minInput.data('text', text);
            }

            function setMax(value) {
                value = +value || max;

                $maxInput.val(+value === max ? null : value);
                slider.noUiSlider.set([null, value]);
                setMaxText(value);
            }

            function setMaxText(value) {
                value = +value || max;
                value = parseInt(value);
                let text = value.toLocaleString(_config.lang);

                $maxTextInput.text(text);
                $maxInput.data('text', text);
            }
        })
    });
}
