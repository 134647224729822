"use strict";


export function init() {
    window.addEventListener("cf_consent", callbackFnc);
    window.addEventListener("cf_consent_loaded", callbackFnc);
    console.log("Oxomi overlay script loaded");
}

function callbackFnc(e) {
    let consent = e.detail || null;
    let elements = document.querySelectorAll(".js-cookie-first-oxomi-overlay");
    console.log("oxomi elements", elements);
    elements.forEach(function(el) {
        // no consent yet or advertising not accepted, hide div
        if (!consent || !consent.functional) {
            el.style.display = 'block';
        } else {
            el.style.display = 'none';
        }
    });

}
