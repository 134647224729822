"use strict";

export function initInScope($scope) {
    $scope.find('.js-agb').each(function () {
        let $agbWrapper = $(this);
        let $agbCheckbox = $agbWrapper.find('.js-agb__checkbox');
        let $agbBtn = $agbWrapper.find('.js-agb__submit-btn');

        // if checkbox is checked, enable button
        $agbCheckbox.on('change', function () {
            console.log('change');

            if ($agbCheckbox.is(':checked')) {
                console.log("checked");
                $agbBtn.prop('disabled', false);
            } else {
                console.log("unchecked");
                $agbBtn.prop('disabled', true);
            }
        });
    });
}