"use strict";

export function initInScope ($scope) {
    // shows and hides the element in 'data-show-if-checked' on change
    let $inputs = $scope.find('.js-show-if-checked');

    $inputs.on('change', changeHandler);

    // check for other radios without the show-if-checked-class to fire the change event (close other elements)
    $inputs.each(function () {
        let $this = $(this);

        if($this.is(':radio')){
            let $otherRadios = $('input[name="' + $this.attr('name') + '"]').not($this).not('.js-show-if-checked');

            $otherRadios.on('change', changeHandler);
        }
    })
}

function changeHandler(evt) {
    let $this = $(this);

    // this part processes all other inputs. So the ones that get automatically unchecked (by the browser) close their targetEl
    if($this.is(':radio')){
        let $otherRadios = $('input[name="' + $this.attr('name') + '"]').not($this);
        $otherRadios.each(function () {
            processInput($(this));
        });
    }

    processInput($this);

}

function processInput($input) {
    let $toggleEl = $($input.data('show-if-checked'));

    if ($input.is(':checked')) {
        $toggleEl.attr('hidden', null);
    } else {
        $toggleEl.attr('hidden', 'hidden');
    }
}
